<template>
    <div class='home-index'>
      <van-search v-model="goodsName" placeholder="请输入搜索关键词"  @click='goSearch' />
        <div>
               <UniqueFeatureAlone class='benner' />
        </div>

        <div class='good-list van-clearfix'>
          <van-list
            :loading="loading"
            :finished="finished"
            finished-text="—————— 已经到底了哦 —————"
            @load="onLoad"
            offset=50

          >
                <!-- :origin-price="item.price" -->

              <van-card 
                v-for='item in goodsList' 
                :key="item.id"
                :price="item.showPrice"
                :desc="item.description"
                :title="item.title"
                :thumb="item.img"
                @click="goGoodsDeatail(item.id)"
            />
         <p slot="loading" >
          <van-loading size="24px">加载中...</van-loading>
         
        </p>
            
          </van-list>
          
   
        </div>
    <NavBarAlone activeFlag='0'/>


    </div>

    
</template>

<script>
import UniqueFeatureAlone from "./uniqueFeatureAlone";
import { Row, Col, List, NavBar } from "vant";
import { Store } from 'vuex';



export default {
    components: {
    UniqueFeatureAlone,
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [NavBar.name]: NavBar,
    
  },
  data(){
      return{
          vertical:true,
          goodsList:undefined,
          goodsLists:undefined,
          loading :false,
          finished :false,
          currentPage:1,
          totalPageNo:1,
          checkis: false,
          goodsName:'',
          goGoodsDeatail: this.Base.goGoodsDetail,


      }
  },
  created(){
    // localStorage.storeId = 1
    let url = location.href
    if(url.indexOf('?storeId=') != -1){
      let storeId = url.split('?storeId=')[1]
      localStorage.storeId = storeId
      localStorage.setItem('storeId', storeId)
    }

  },
  mounted(){
    // document.title=localStorage.activityName

      // localStorage.categoryId = 1101107
      this.getList()
      this.logcode()
      this.getStoreName()
  },

  methods:{
    // 获取店铺名称
    getStoreName(){
      this.$api.home.getStoreInfo(localStorage.storeId).then(res=>{
        if(res.errno === 200){
          document.title = res.data.storeName
        }
      })
    },
      getList(){
        // console.log(localStorage.categoryId);
        this.$api.home
        .getGoodsPageAddress({
          pageSize:10,
          pageNo: this.pageNo,
          // categoryId:localStorage.categoryId
          storeId:localStorage.storeId
        }).then(res =>{
          if(res.errno === 200){
            this.goodsList = res.data.items
            this.currentPage = res.data.pageNo
            this.totalPageNo = res.data.totalPageNo;

            this.goodsList.forEach(v=>{
                v.price = (v.price / 100).toFixed(2)
                v.vipPrice = (v.vipPrice / 100).toFixed(2)

                v.originalPrice = (v.originalPrice /100).toFixed(2)
                })
          }else{
            this.$toast(res.errmsg)
          }
            
        
            // console.log(this.goodsList);
        })
      },
     goSearch() {
      this.$router.push({ name: "search" });
    },
      onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading =true
      setTimeout(() => {
        this.cancatOrderList();

      }, 2000);
    },
      cancatOrderList() {
      this.loading = true;

      this.currentPage++;
      if (this.currentPage > this.totalPageNo) {
        this.loading = false;
        return (this.finished = true);
      }
  
      this.$api.home
        .getGoodsPageAddress({
          pageSize:10,
          pageNo: this.currentPage,
          storeId:localStorage.storeId

          // categoryId:localStorage.categoryId,
        })
        .then((res) => {
          if (res.errno === 200) {
            console.log(res);
            this.loading = false; // 关闭上拉加载中
            let arr = res.data.items
              arr.forEach(v=>{
                v.price = (v.price / 100).toFixed(2)
                v.vipPrice = (v.vipPrice / 100).toFixed(2)

                v.originalPrice = (v.originalPrice /100).toFixed(2)
                })
            this.goodsList = this.goodsList.concat(arr);
          } else {
            this.error = true;
          }
        });
    },


        // goGoodsDeatail(id){
        //     this.$router.push({path:`/goodsdetail`,query:{
        //         id:id
        //     }});
        // },


        logcode() {
     
         if (!sessionStorage.openId) {
         let url = location.href;

      
        if (url.indexOf("openid") != -1) {
          let text = url.split("?openid=")[1];
          let text1 = text.split("&categoryId")[0];
          // let text2 = text1.split("#")[0];
          let openId = text1;
         
          sessionStorage.openId = openId ? openId : sessionStorage.openId;
        }
      }

    },

  }
}
</script>

<style lang="less" scoped>
@import "../../assets/styles/element.less";
@red: #e94c67;
.categroy-img-goods {
  // padding-top: 3rem;
  box-sizing: border-box;
  // background-color: @red;
  min-height: 100vh;
  width: 100%;
  /deep/.van-nav-bar {
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.3s linear;
    .van-icon {
      color: #fff;
      font-size: 0.44rem /* 22/50 */;
    }
    .van-nav-bar__title {
      color: #fff;
      letter-spacing: 2px;
    }
  }
  .categroy-mansion {
    // position: relative;
    // top: 46px;
    width: 100%;
    box-sizing: border-box;
    // padding: 0 0.12rem /* 6/50 */;
    margin-bottom: -0.5rem /* 25/50 */;
    .categroy-mansion-img {
      display: flex;
      p {
        // padding: 0 0.12rem /* 6/50 */;
        img {
          width: 100%;
        }
      }
    }
    .categroy-mansion-title {
      text-align: center;
      color: #239cd5;
      font-size: 0.38rem /* 19/50 */;
      font-weight: bold;
      letter-spacing: 0.4em;
      line-height: 2.4;
    }
  }
  .tip-img {
    width: 100%;
    margin-bottom: 0.16rem /* 8/50 */;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .van-list {
    margin-top: 0.64rem /* 32/50 */;
    padding: 0 0.12rem /* 6/50 */;
    &.vertival {
      padding: 0 0.24rem /* 12/50 */;
    }
    .van-col {
      margin-bottom: 0.28rem /* 14/50 */;
      ul {
        background-color: rgb(255, 255, 255);
        height: 4.8rem /* 240/50 */;
        box-sizing: border-box;
        font-size: 0.28rem /* 14/50 */;
        padding: 0 0 0.24rem /* 12/50 */;
        border-radius: 0.12rem /* 6/50 */;
        li {
          padding: 0 0.16rem /* 8/50 */;
          line-height: 0.34rem /* 17/50 */;
          color: #544f4f;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: rows;
          -webkit-line-clamp: 2;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          &:first-child {
            padding: 0;
            height: 3.2rem /* 160/50 */;
          }
          img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 0.12rem /* 6/50 */;
            border-top-right-radius: 0.12rem /* 6/50 */;
          }
        }
      }
    }
    .goodslist-row {
      padding: 0;
      /deep/ .van-card {
        box-shadow: none;
      }
    }
    /deep/ .van-list__finished-text {
      color: #fff;
    }
  }
}
.benner{
    padding: .3rem .3rem;
   
}
/deep/ .van-swipe{
    height: 2.6rem;
    border-radius: .2rem;
    overflow: hidden;
    // box-shadow: 0px 15px 25px 0px rgba(135, 29, 33, 0.2);
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.1);
}
.home-index{
    padding-bottom: 1.4rem;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #fff;
    // overflow-x: hidden;
}
.van-card{
    margin-top: .14rem;
    background-color: transparent;

}
.van-card__thumb{
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .30rem;
}
.van-card__title{
    margin-top: .2rem;
font-size: .28rem;
font-family: PingFang SC;
font-weight: bold;
color: #333333;
line-height: 1.3;

}
.van-card__title{
    max-height: 1rem;
}
.van-card__desc{
margin-top: .15rem;
font-size: .22rem;
font-family: PingFang SC;
font-weight: 400;
color: #999999;
line-height: .3rem;
}
.van-card__price{
padding-bottom: .24rem;
font-size: .24rem;
font-family: PingFang SC;
font-weight: bold;
color: #D93B3B;
line-height: .24rem;
}
.van-card__price-integer{
    font-size: .32rem;

}
.van-card__origin-price{
  
font-size: .22rem;
font-family: PingFang SC;
font-weight: 400;
text-decoration: line-through;
color: #999999;
line-height: .30rem;
}
.good-list{
   
    padding-bottom: .2rem;
    
}

</style>